import { combineReducers } from "redux";
import accountReducer from "./accountReducer";
import showHideDataReducer from "./showHideDataReducer";
import tokenDataReducer from "./tokenDataReducer";

const reducers = combineReducers({ showHideDataReducer, tokenDataReducer, accountReducer });

export type State = ReturnType<typeof reducers>;

export default reducers;
