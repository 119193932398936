import { FC } from "react";

const PageLoader: FC = () => {
  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-b-4 border-brightGreen" />
    </div>
  );
};

export default PageLoader;
