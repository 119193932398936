import { ShowHideActions } from "../actions";
import { ActionTypes } from "../actionTypes";

export interface ShowHideData {
  openSheet: boolean;
  openAccountModal: boolean;
  openNotificationsModal: boolean;
  openRFTImageModal: boolean;
  openRFTHistoryModal: boolean;
  showUserSidebar: boolean;
  showRFTTabs: boolean;
  showGenesisTabs: boolean;
  showFoundersTabs: boolean;
}

const initialState: ShowHideData = {
  openSheet: false,
  openAccountModal: false,
  openNotificationsModal: false,
  openRFTHistoryModal: false,
  showUserSidebar: false,
  showRFTTabs: false,
  showGenesisTabs: false,
  showFoundersTabs: true,
  openRFTImageModal: false,
};

const showHideDataReducer = (state: ShowHideData = initialState, action: ShowHideActions): ShowHideData => {
  switch (action.type) {
    case ActionTypes.SET_OPEN_SHEET:
      return { ...state, openSheet: action.payload };

    case ActionTypes.SET_OPEN_ACCOUNT_MODAL:
      return { ...state, openAccountModal: action.payload };

    case ActionTypes.SET_OPEN_NOTIFICATIONS_MODAL:
      return { ...state, openNotificationsModal: action.payload };

    case ActionTypes.SET_SHOW_USER_SIDEBAR:
      return { ...state, showUserSidebar: action.payload };

    case ActionTypes.SET_RFT_TABS:
      return { ...state, showRFTTabs: action.payload };

    case ActionTypes.SET_GENESIS_TABS:
      return { ...state, showGenesisTabs: action.payload };

    case ActionTypes.SET_FOUNDERS_INFO:
      return { ...state, showFoundersTabs: action.payload };

    case ActionTypes.SET_OPEN_RFT_IMAGE_MODAL:
      return { ...state, openRFTImageModal: action.payload };

    case ActionTypes.SET_OPEN_RFT_HISTORY_MODAL:
      return { ...state, openRFTHistoryModal: action.payload };

    default:
      return state;
  }
};

export default showHideDataReducer;
