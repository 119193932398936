import { UserCredential } from "firebase/auth";
import { AccountActions } from "../actions";
import { ActionTypes } from "../actionTypes";

export type UserTypes = {
  name: string;
  showOpenSeaAccountLink: boolean;
  bio: string;
  // email: string;
  twitterLink: string;
  instagramLink: string;
  linkedInLink: string;
  discordLink: string;
  websiteLink: string;
  walletAddress: string;
  // profileImage: string;
  // bannerImage: string;
};

interface AccountData {
  currentAccount: string;
  userDetails: UserTypes;
  adminDetails: any;
}

const initialState: AccountData = {
  currentAccount: null,
  userDetails: null,
  adminDetails: null,
};

const accountReducer = (state: AccountData = initialState, action: AccountActions): AccountData => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_ACCOUNT:
      return { ...state, currentAccount: action.payload };

    case ActionTypes.SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };

    case ActionTypes.SET_ADMIN_DETAILS:
      return { ...state, adminDetails: action.payload };

    default:
      return state;
  }
};

export default accountReducer;
