import { TokenDataActions } from "../actions";
import { ActionTypes } from "../actionTypes";

export type SelectedRFTImageTypes = {
  imageSrc: string;
  showModal: boolean;
};
export interface TokenData {
  tokenData: any[];
  NFTOwners: any[];
  NFTSTransfers: any[];
  selectedRFTImage: string;
}

const initialState: TokenData = {
  tokenData: null,
  NFTOwners: null,
  NFTSTransfers: null,
  selectedRFTImage: null,
};

const tokenDataReducer = (state: TokenData = initialState, action: TokenDataActions): TokenData => {
  switch (action.type) {
    case ActionTypes.SET_TOKEN_DATA:
      return { ...state, tokenData: action.payload };

    case ActionTypes.SET_NFTOWNERS:
      return { ...state, NFTOwners: action.payload };

    case ActionTypes.SET_NFTS_TRANSFERS:
      return { ...state, NFTSTransfers: action.payload };

    case ActionTypes.SET_SELECTED_RFT_IMAGE:
      return { ...state, selectedRFTImage: action.payload };

    default:
      return state;
  }
};

export default tokenDataReducer;
