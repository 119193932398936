export enum ActionTypes {
  SET_TOKEN_DATA = "SET_TOKEN_DATA",
  SET_CURRENT_ACCOUNT = "SET_CURRENT_ACCOUNT",
  SET_OPEN_SHEET = "SET_OPEN_SHEET",
  SET_OPEN_ACCOUNT_MODAL = "SET_OPEN_ACCOUNT_MODAL",
  SET_USER_DETAILS = "SET_USER_DETAILS",
  SET_ADMIN_DETAILS = "SET_ADMIN_DETAILS",
  SET_NFTOWNERS = "SET_NFTOWNERS",
  SET_OPEN_NOTIFICATIONS_MODAL = "SET_OPEN_NOTIFICATIONS_MODAL",
  SET_NFTS_TRANSFERS = "SET_NFTS_TRANSFERS",
  SET_SHOW_USER_SIDEBAR = "SET_SHOW_USER_SIDEBAR",
  SET_FOUNDERS_INFO = "SET_FOUNDERS_INFO",
  SET_GENESIS_TABS = "SET_GENESIS_TABS",
  SET_RFT_TABS = "SET_RFT_TABS",
  SET_SELECTED_RFT_IMAGE = "SET_SELECTED_RFT_IMAGE",
  SET_OPEN_RFT_IMAGE_MODAL = "SET_OPEN_RFT_IMAGE_MODAL",
  SET_OPEN_RFT_HISTORY_MODAL = "SET_OPEN_RFT_HISTORY_MODAL",
}
