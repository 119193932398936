import { Dispatch } from "react";
import { AccountActions, ShowHideActions, TokenDataActions } from "../actions";
import { ActionTypes } from "../actionTypes";

export const setOpenSheet = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_OPEN_SHEET, payload: data });
};

export const setOpenAccountModal = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_OPEN_ACCOUNT_MODAL, payload: data });
};

export const setOpenNotificationsModal = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) =>
    dispatch({ type: ActionTypes.SET_OPEN_NOTIFICATIONS_MODAL, payload: data });
};

export const setShowUserSidebar = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_SHOW_USER_SIDEBAR, payload: data });
};

export const setShowRFTTabs = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_RFT_TABS, payload: data });
};

export const setShowGenesisTabs = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_GENESIS_TABS, payload: data });
};

export const setShowFoundersTabs = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) => dispatch({ type: ActionTypes.SET_FOUNDERS_INFO, payload: data });
};

export const setOpenRFTImageModal = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) =>
    dispatch({ type: ActionTypes.SET_OPEN_RFT_IMAGE_MODAL, payload: data });
};
export const setOpenRFTHistoryModal = (data: boolean) => {
  return (dispatch: Dispatch<ShowHideActions>) =>
    dispatch({ type: ActionTypes.SET_OPEN_RFT_HISTORY_MODAL, payload: data });
};

export const setTokenData = (data: any[]) => {
  return (dispatch: Dispatch<TokenDataActions>) => dispatch({ type: ActionTypes.SET_TOKEN_DATA, payload: data });
};

export const setNFTOwners = (data: any[]) => {
  return (dispatch: Dispatch<TokenDataActions>) => dispatch({ type: ActionTypes.SET_NFTOWNERS, payload: data });
};

export const setNFTSTransers = (data: any[]) => {
  return (dispatch: Dispatch<TokenDataActions>) => dispatch({ type: ActionTypes.SET_NFTS_TRANSFERS, payload: data });
};

export const setSelectedRFTImage = (data: string) => {
  return (dispatch: Dispatch<TokenDataActions>) =>
    dispatch({ type: ActionTypes.SET_SELECTED_RFT_IMAGE, payload: data });
};

export const setCurrentAccount = (data: string) => {
  return (dispatch: Dispatch<AccountActions>) => dispatch({ type: ActionTypes.SET_CURRENT_ACCOUNT, payload: data });
};

export const setUserDetails = (data: any) => {
  return (dispatch: Dispatch<AccountActions>) => dispatch({ type: ActionTypes.SET_USER_DETAILS, payload: data });
};

export const setAdminDetails = (data: any) => {
  return (dispatch: Dispatch<AccountActions>) => dispatch({ type: ActionTypes.SET_ADMIN_DETAILS, payload: data });
};
