import { FC, lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PageLoader from "./core/components/PageLoader";

const LazyAdminRoutes = lazy(() => import("./admin/routes/AdminRoutes"));
const LazyUserRoutes = lazy(() => import("./user/routes/UserRoutes"));

const LazyRFTStory = lazy(() => import("./user/pages/RFTStory"));
const LazyHome = lazy(() => import("./user/pages/HomeMap"));

const App: FC = () => {
  // useEffect(() => {
  //   localStorage.removeItem("persist:root");
  //   localStorage.removeItem("persist:primary");
  // }, []);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/*">
          <Route index element={<LazyHome />} />
          <Route path=":token_id" element={<LazyHome />} />
          <Route path="landmark/:marker_title" element={<LazyHome />} />
          <Route path="intro" element={<LazyRFTStory />} />
        </Route>
        {/* admin routes */}
        <Route path="admin/*" element={<LazyAdminRoutes />} />

        {/* user routes */}
        <Route path="user/*" element={<LazyUserRoutes />} />

        {/* 404 fallback */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Suspense>
  );
};

export default App;
